@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ================================================================================
                                General Settings
================================================================================
*/
* {
  margin: 0;
  padding: 0;
}

.desktopWrapper {
  margin: auto;
  max-width: 1000px;
}

.backgroundGrey {
  background-color: #eeeeee !important;
}

#retreatDetails {
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

#retreatDetails p {
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

#retreatDetails h1 {
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 30px;
  line-height: 60px;
  font-weight: 500;
  color: #1d1d1d;
}

#retreatDetails h2 {
  text-align: left;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 25px;
  width: 100%;
  line-height: 60px;
  font-weight: 400;
  color: #1d1d1d;
  padding: 10px 0 0 0;
}

#retreatDetails h3 {
  color: #636366;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
}

#retreatDetails_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100vw;
  min-height: 100vh;
}

#mobileHead {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: 75px;
  padding: 0 25px;
  top: 0;
  position: -webkit-sticky !important;
  position: sticky !important;
  background-color: white;
  box-shadow: 0 0 5px 0 #6363669a;
  z-index: 5;
}

#mobileHead img {
  height: 30px;
  width: auto;
}

#mobileDivider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: auto;
  width: 100vw;
  margin: 15px 0;
  margin: 0 0 !important;
}

#mobileDivider hr {
  width: 30%;
}

#mobileDivider img {
  width: 20px;
  height: auto;
  padding: 0 25px;
}

#retreatCover {
  height: auto;
  width: 100vw;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 !important;
}

#retreatCover h1 {
  color: #636366;
  font-weight: 500;
  margin: 10px 0;
}

#retreatCover .retreatCover_img {
  width: 100vw;
  height: 28vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#retreatCover .retreatCover_img img {
  object-fit: cover;
  width: 100vw;
  height: 28vh;
}

#retreatCover .retreatCover_img .saveIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #eeeeee;
  transition: 200ms ease-out;
  align-self: flex-end;
  position: absolute;
  top: calc(75px + 25vh);
  right: 18px;
}

#retreatCover .retreatCover_img .saveIcon img {
  width: 24px;
  height: auto;
  object-fit: cover;
}

#retreatCover .retreatCover_split {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 !important;
}

#retreatCover h3 {
  margin: 30px 20px 0px 20px;
  text-align: center;
}

#retreatCover hr {
  width: 80%;
  background-color: #eeeeee;
  height: 1px;
  border: none;
}

#retreatCover .retreatCover_description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 40px;
  padding: 0 10px;
}

#retreatCover .retreatCover_description img {
  width: 18px;
  padding: 0 10px;
}

#retreatCover .retreatCover_description p {
  text-align: center;
}

#retreatCover .retreatCover_footnote {
  width: 100vw;
  text-align: right;
  color: #636366;
  font-size: 10px;
  margin: 10px 10px 2px 0;
}

#bookRetreat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: white;
  width: 100vw;
  bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0 0 5px 0 #6363669a;
  /*   button:first-child {
    @include btn(w_3, w_1);
  } */
}

#bookRetreat #price {
  width: 55vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-items: flex-start;
  margin-left: 10px;
  margin-top: 45px;
}

#bookRetreat #price #pricePerNight {
  font-weight: 600;
}

#bookRetreat #price #totalPrice {
  font-size: 12px;
  color: #636366;
}

#bookRetreat #book {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-items: flex-start;
}

#bookRetreat #book #dates {
  width: 45vw;
  font-size: 14px;
  margin: 10px 0px 0px 15px;
}

#bookRetreat #book #buttonLink {
  margin: 10px 80px 12px 15px;
}

#bookRetreat #book button {
  min-width: 130px;
  color: white;
  background-color: #235745ff;
  padding: 10px 10px;
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin: 10px 10px;
  width: 45vw;
  margin: 0;
  padding: 15px 10px;
  font-size: 16px;
}

#bookRetreat #book button:hover {
  cursor: pointer;
}

#bookRetreat #book button:disabled {
  background-color: #636366;
}

.detailsWrapper {
  height: auto;
  width: 90vw;
  /*   @include flex_center(column);
  justify-content: flex-start;
  text-align: left;
  margin: 0 0 20px 0; */
  align-items: flex-start;
}

#itinerary {
  height: auto;
  width: 100vw;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #eeeeee;
  margin-bottom: 0 !important;
  /*   margin: 0 0; */
}

#itinerary ul {
  font-size: 16px;
  margin: 10px 0;
  padding-left: 1.5em;
  z-index: 0;
}

#itinerary li {
  font-size: 14px;
  font-family: "IBM Plex Sans";
  margin-top: 0;
  margin-bottom: 3px;
  list-style-type: none;
  line-height: 19px;
  position: relative;
  padding: 0 5px 5px 10px;
  color: #2a213e;
}

#itinerary li:before {
  content: "";
  width: 8px;
  height: 8px;
  border: 2px solid #235745ff;
  position: absolute;
  top: 7px;
  left: -20px;
  border-radius: 50%;
  border-color: #428D73FF !important;
}

#location {
  height: auto;
  width: 100vw;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #eeeeee;
  margin-top: 0;
  margin-bottom: 0 !important;
}

#location h2 {
  width: 90vw;
}

#locationMap {
  height: 35vh;
  width: 100vw;
}

#locationMap .marker img {
  position: absolute;
  transform: translate(-50%, -50%);
}

#expandable {
  border-top: 1px solid #d0d0d3;
  border-bottom: 1px solid #d0d0d3;
  width: 100%;
}

#expandable .expandable_simple {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 10px 0;
}

#expandable .expandable_simple img {
  width: 20px;
  height: auto;
}

#expandable .expandable_simple h4 {
  font-size: 14px;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  font-weight: 500;
}

#expandable .expandable_collapsed {
  text-align: left;
}

#expandable .expandable1_collapsed ul {
  font-size: 16px;
  margin: 10px 0;
  padding-left: 1.5em;
}

#expandable .expandable1_collapsed li {
  font-size: 14px;
  font-family: "IBM Plex Sans";
  margin-top: 0;
  margin-bottom: 3px;
  list-style-type: none;
  line-height: 19px;
  position: relative;
  padding: 0 5px 5px 10px;
  color: #2a213e;
}

#expandable .expandable1_collapsed li:before {
  content: "";
  width: 8px;
  height: 8px;
  border: 2px solid #de017b;
  position: absolute;
  top: 7px;
  left: -20px;
  border-radius: 50%;
  border-color: #428D73FF !important;
}

#expandable .expandable2_collapsed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
}

#expandable .expandable2_collapsed img {
  width: 20px;
  height: auto;
  padding: 0 20px;
}

#expandable .expandable_extraInfo {
  text-align: center;
  color: #428D73FF;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
}

#property {
  height: auto;
  width: 100vw;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: white;
  margin-top: 0 !important;
  margin-bottom: 0;
  text-align: center;
  /*   img {
    width: 100%;
    object-fit: cover;
    height: 300px;
  }
 */
}

#property .property_details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#property .property_details p {
  font-size: 16px;
  color: #636366;
  line-height: 60px;
  font-size: 20px;
  line-height: 60px;
  font-weight: 400;
  padding: 10px 0 0 0;
}

#property h3 {
  font-size: 15px;
  margin: 10px 10px;
}

#property h4 {
  font-size: 20px;
  font-family: "IBM Plex Sans";
  font-weight: 500;
}

#property ul {
  text-align: center;
  position: relative;
  padding-left: 0;
  margin-top: 11px;
  margin-bottom: 0;
  font-size: 16px;
  z-index: 0;
}

#property .is-active::after {
  width: 8px;
  height: 8px;
  background-color: #235745ff;
  content: "";
  border-radius: 50%;
  display: block;
}

#property li::after {
  content: "";
  background-color: #ccc;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: block;
}

#property li {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 6px;
  color: #2a213e;
  text-align: -webkit-match-parent;
}

#property .property_footnote {
  width: 100vw;
  text-align: right;
  color: #636366;
  font-size: 12px;
  margin: 15px 10px 2px 0;
}

.highlights_slider {
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

.highlights_slider img {
  width: 90vw;
  height: 300px;
  object-fit: cover;
  margin: 10px 0;
}

.highlights_slider ul {
  text-align: center;
  position: relative;
  padding-left: 0;
  margin-top: 11px;
  margin-bottom: 0;
  font-size: 16px;
  z-index: 0;
}

.highlights_slider .is-active::after {
  width: 8px;
  height: 8px;
  background-color: #235745ff;
  content: "";
  border-radius: 50%;
  display: block;
}

.highlights_slider li::after {
  content: "";
  background-color: #ccc;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: block;
}

.highlights_slider li {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 6px;
  color: #2a213e;
  text-align: -webkit-match-parent;
}

#allDetails {
  height: auto;
  width: 100vw;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: white;
  margin: 0 0 !important;
}

#allDetails button {
  width: 100%;
  color: #636366;
  background-color: #ececec17;
  padding: 10px 10px;
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin: 10px 0px;
  margin-top: 20px;
}

#allDetails button:hover {
  background-color: white;
}

#allDetails h4 {
  font-family: Poppins, sans-serif;
  text-align: center;
  font-weight: 300;
  padding: 10px 0 0px 0;
}

#recommendation {
  height: auto;
  width: 100vw;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 0 !important;
  background-color: #eeeeee;
  padding-bottom: 20px;
  margin: 0 0 30px 0;
}

#recommendation img {
  width: 65vw;
  height: 17vh;
}

#recommendation a {
  text-decoration: none !important;
  color: black;
}

#recommendation .highlights_slider_content {
  padding: 0 10px;
  text-decoration: none !important;
}

#recommendation .highlights_slider_content:first-child {
  padding: 0 10px 0 0;
}

#allPhotos {
  height: auto;
  width: 100vw;
  margin: 15px 0;
  max-height: 100vh !important;
  background-color: white;
  margin: 0 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

#allPhotos .allPhotos_goBack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100vw;
  justify-content: flex-start;
  align-items: center;
  padding: 5vh 0 0 20px !important;
  height: 20vh;
}

#allPhotos .allPhotos_goBack img {
  width: 20px;
  height: auto;
  padding: 0 20px 0 0;
}

#allPhotos div:first-child, #allPhotos div:last-child {
  height: 20%;
  padding: 0 20px;
  color: #636366;
}

#allPhotos .allPhotos_slider {
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between !important;
  align-items: flex-start;
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch !important;
  margin: 0 0 10px 0;
}

#allPhotos .allPhotos_slider .allPhotos_slider_content {
  margin: 0 0 !important;
  padding: 0 0 !important;
}

#allPhotos .allPhotos_slider .allPhotos_slider_content img {
  width: 90vw;
  height: 300px;
  object-fit: cover;
  padding: 0 10px 0 0;
}

#allPhotos ul {
  text-align: center;
  position: relative;
  padding-left: 0;
  margin-top: 11px;
  margin-bottom: 0;
  font-size: 16px;
  z-index: 0;
}

#allPhotos .is-active::after {
  width: 8px;
  height: 8px;
  background-color: #235745ff;
  content: "";
  border-radius: 50%;
  display: block;
}

#allPhotos li::after {
  content: "";
  background-color: #ccc;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: block;
}

#allPhotos li {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 6px;
  color: #2a213e;
  text-align: -webkit-match-parent;
}

@media (min-width: 700px) {
  .logo_fullScreen {
    font-family: Georgia, Times, 'Times New Roman', serif;
    font-size: 40px !important;
    line-height: 60px;
    font-weight: 500;
    color: #1d1d1d !important;
    padding: 20px 0 20px 0 !important;
    background-color: white;
    box-shadow: 0 0 5px 0 #6363669a;
    text-align: center;
  }
  #retreatDetails h3 {
    font-size: 16px !important;
  }
  #retreatCover {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 500px;
    justify-content: space-between !important;
  }
  #retreatCover .retreatCover_split {
    width: 50%;
  }
  #retreatCover .retreatCover_split h3 {
    margin: 30px 20px 20px 20px;
  }
  #retreatCover h1 {
    font-size: 35px;
  }
  #retreatCover .retreatCover_img {
    height: 500px !important;
    width: 50% !important;
  }
  #retreatCover .retreatCover_img img {
    height: 500px;
    width: 100%;
  }
  #retreatCover .retreatCover_description p {
    font-size: 16px !important;
  }
  #retreatCover .retreatCover_foot p {
    font-size: 18px !important;
  }
  h2 {
    font-size: 30px !important;
  }
  #itinerary {
    margin-top: 0 !important;
  }
  #itinerary .itinerary_split {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-items: flex-start;
    border-bottom: 20px solid #eeeeee;
    background-color: white;
    margin-bottom: 20px;
    /* .detailsWrapper {
        width: calc(50vw - 10px) !important;
      } */
  }
  #itinerary .itinerary_split .detailsWrapper2 {
    background-color: white;
    width: 100% !important;
  }
  #itinerary .itinerary_split .detailsWrapper_inside {
    width: 90% !important;
    margin: 0 50px;
  }
  #itinerary .detailsWrapper .detailsWrapper_flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-items: flex-start !important;
    justify-content: space-between !important;
    align-content: space-between !important;
    padding-bottom: 30px;
  }
  #itinerary .detailsWrapper .detailsWrapper_flex img {
    width: 46% !important;
    height: 300px !important;
    padding: 0 0 !important;
    margin: 0 0 20px 0;
  }
  #itinerary .detailsWrapper .detailsWrapper_flex ul {
    width: 40%;
  }
  #itinerary .detailsWrapper .detailsWrapper_flex li {
    font-size: 17px !important;
    line-height: 20px;
    margin-bottom: 15px !important;
  }
  .retreatDetails_split {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    background-color: white;
    margin-top: 20px;
    /*     border-top: 20px solid color(w_1); */
    width: 100%;
    height: 675px;
  }
  .retreatDetails_split h2 {
    width: 100% !important;
  }
  .retreatDetails_split .detailsWrapper {
    width: 100% !important;
  }
  .retreatDetails_split #allDetails .detailsWrapper .one button:first-child {
    background-color: #eeeeee;
    font-weight: 600;
    color: black;
    font-size: 16px;
  }
  .retreatDetails_split #allDetails .detailsWrapper .two button:nth-child(2) {
    background-color: #eeeeee;
    font-weight: 600;
    color: black;
    font-size: 16px;
  }
  .retreatDetails_split #allDetails .detailsWrapper .three button:last-child {
    background-color: #eeeeee;
    font-weight: 600;
    color: black;
    font-size: 16px;
  }
  .retreatDetails_split #location {
    background-color: white !important;
    padding: 0 30px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 35% !important;
    margin-top: 0;
    margin-bottom: 0 !important;
    align-items: flex-start !important;
    /*       border-right: 20px solid color(w_1); */
  }
  .retreatDetails_split #location h2 {
    margin-bottom: 20px;
  }
  .retreatDetails_split hr {
    background-color: #eeeeee;
    width: 1px;
    height: 600px;
    border: none;
    margin: 0 20px;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .retreatDetails_split #locationMap {
    height: 560px !important;
    width: 100% !important;
  }
  .retreatDetails_split #allDetails {
    width: 48% !important;
    padding: 0 30px 0 30px !important;
    background-color: white !important;
  }
  .retreatDetails_split #allDetails .slider2 {
    background-color: #eeeeee;
  }
  .retreatDetails_split #allDetails button {
    background-color: #eeeeee;
  }
  .retreatDetails_split #allDetails .detailsWrapper {
    width: 100%;
  }
  .retreatDetails_split .slider2 {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: white;
    padding: 20px 0;
  }
  .retreatDetails_split .slider2 .checkout_slider {
    width: 90% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-content: space-between !important;
    align-items: flex-start;
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch !important;
  }
  .retreatDetails_split .slider2 .checkout_slider img {
    width: 432px !important;
    height: 400px !important;
    object-fit: cover;
    padding: 0 0 !important;
    margin: 0 0 !important;
  }
  #howItWorks {
    width: 1000px;
    margin-top: 20px;
    background-color: #eeeeee;
  }
  #howItWorks h2 {
    padding-left: 30px !important;
  }
  #howItWorks .detailsWrapper {
    width: auto !important;
  }
  #howItWorks .howItWorks_steps {
    background-color: white !important;
    width: 30%;
    height: 410px;
    justify-content: flex-start;
    margin-bottom: 30px !important;
  }
  #howItWorks .howItWorks_steps h1 {
    height: 20%;
  }
  #howItWorks .howItWorks_steps h3 {
    height: 20%;
    margin-top: 30px !important;
    -ms-grid-row-align: center;
    align-self: center;
  }
  #howItWorks .howItWorks_steps p {
    height: 60%;
    margin: 0 20px 40px 20px !important;
  }
  #howItWorks .howItWorks_slider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .retreatDetails2 .retreatCover_info2 {
    width: 40vw !important;
  }
  .slider2 .checkout_slider img {
    height: 500px;
  }
  .highlights_slider img {
    width: 90vw;
    height: 60vh;
    object-fit: contain;
    margin: 10px 0;
  }
  .allPhotos_slider {
    height: auto;
  }
  #allPhotos .allPhotos_slider .allPhotos_slider_content {
    height: auto;
    text-align: center;
  }
  #allPhotos .allPhotos_slider .allPhotos_slider_content h4 {
    padding: 0 0;
  }
  #allPhotos .allPhotos_slider .allPhotos_slider_content img {
    width: 90vw;
    height: 60vh;
    object-fit: contain;
    padding: 0 10px 0 0;
  }
  #allPhotos div:last-child {
    height: 10%;
    padding: 0 10px;
  }
  #recommendation {
    border-top: 20px solid white;
    width: 1000px;
  }
  #recommendation .detailsWrapper {
    width: auto;
    margin: 0 30px;
    background-color: white;
  }
  #recommendation .highlights_slider {
    justify-content: flex-start;
    width: 100%;
  }
  #recommendation .highlights_slider_content h4 {
    font-size: 20px;
    margin: 10px 0;
  }
  #recommendation .highlights_slider_content img {
    width: 100%;
    height: 25%;
    object-fit: cover;
  }
  #recommendation .highlights_slider_content:first-child {
    padding: 0 40px 0 0;
  }
  #retreatCover hr {
    width: 60%;
  }
  h2 {
    text-align: center;
  }
  #bookRetreat {
    align-self: flex-end;
  }
  #bookRetreat #book {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-items: center;
  }
  #bookRetreat #book #dates {
    width: 200px;
    margin: 0 10px 0 0;
    font-size: 16px;
  }
  #bookRetreat #book button {
    width: 300px;
    font-size: 17px;
  }
  #bookRetreat #book button:disabled {
    background-color: #636366;
  }
  #bookRetreat #price {
    align-items: center;
    width: 15vw !important;
    margin: 0 20px !important;
  }
  #bookRetreat #pricePerNight {
    font-size: 16px;
  }
  #bookRetreat #totalPrice {
    font-size: 14px;
  }
}

.retreatDetails2 {
  /*   #allDetails button:first-child {
    padding: 20px 10px;
    font-size: 14px;
    font-family: Poppins, sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    text-align: left;
  } */
}

.retreatDetails2 .detailsWrapper2 {
  background-color: white;
  width: 100vw;
}

.retreatDetails2 .detailsWrapper2 .detailsWrapper_inside {
  width: 90vw !important;
  -ms-grid-row-align: center;
  align-self: center;
  margin: 0 5vw;
}

.retreatDetails2 .detailsWrapper2:first-child {
  background-color: #eeeeee !important;
}

.retreatDetails2 h2 {
  text-align: center;
}

.retreatDetails2 #itinerary .detailsWrapper img {
  object-fit: cover;
  width: 78vw;
  height: 200px;
  padding: 0 30px;
  margin: 5px 0 20px 0;
}

.retreatDetails2 #expandable {
  border: none !important;
}

.retreatDetails2 #location {
  background-color: white;
}

.retreatDetails2 #allDetails {
  background-color: #eeeeee;
  padding: 0 0 20px 0;
  margin: 0;
}

.retreatDetails2 #recommendation {
  background-color: white;
}

.retreatDetails2 .retreatCover_info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.retreatDetails2 .retreatCover_info img {
  width: 20px;
  padding: 0 10px;
}

.retreatDetails2 .retreatCover_info2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100vw;
  margin: 40px 0 0 0;
}

.retreatDetails2 .retreatCover_info2 .retreatCover_foot {
  margin: 0 0 !important;
  width: 50vw !important;
  text-align: center;
  color: #636366;
  font-size: 16px !important;
}

.retreatDetails2 .retreatCover_info2 .retreatCover_foot p {
  font-size: 16px !important;
}

.retreatDetails2 #allDetails .detailsWrapper .one button:first-child {
  background-color: white;
  font-weight: 600;
  color: black;
  font-size: 16px;
}

.retreatDetails2 #allDetails .detailsWrapper .two button:nth-child(2) {
  background-color: white;
  font-weight: 600;
  color: black;
  font-size: 16px;
}

.retreatDetails2 #allDetails .detailsWrapper .three button:last-child {
  background-color: white;
  font-weight: 600;
  color: black;
  font-size: 16px;
}

.retreatDetails2 #allDetails .housing_details_nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid white;
}

.retreatDetails2 #allDetails .housing_details_nav button {
  text-align: center;
  border-right: 1px solid white !important;
  margin-bottom: 0 !important;
}

.retreatDetails2 #allDetails .housing_details_nav button:last-child {
  border-right: none !important;
}

.retreatDetails2 .expandable_collapsed {
  padding: 50px 0;
  background-color: white;
}

.slider2 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: white;
  padding: 20px 0;
}

.slider2 .checkout_slider {
  width: 80vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between !important;
  align-items: flex-start;
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

.slider2 .checkout_slider img {
  width: 80vw;
  height: 250px;
  object-fit: cover;
  padding: 0 0 !important;
  margin: 0 0 !important;
}

.slider2 ul {
  text-align: center;
  position: relative;
  padding-left: 0;
  margin-top: 11px;
  margin-bottom: 0;
  font-size: 16px;
  z-index: 0;
}

.slider2 .is-active::after {
  width: 8px;
  height: 8px;
  background-color: #235745ff;
  content: "";
  border-radius: 50%;
  display: block;
}

.slider2 li::after {
  content: "";
  background-color: #ccc;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: block;
}

.slider2 li {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 6px;
  color: #2a213e;
  text-align: -webkit-match-parent;
}

#howItWorks .howItWorks_steps {
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 10px 10px;
  max-width: 98vw;
}

#howItWorks .howItWorks_steps h1 {
  color: #1d1d1d;
  font-size: 40px;
  margin-top: 30px;
}

#howItWorks .howItWorks_steps h3 {
  font-size: 18px;
  margin-top: 20px;
}

#howItWorks .howItWorks_steps p {
  font-size: 14px;
  margin: 20px 20px 40px 20px;
  text-align: center;
}

@media (max-width: 700px) {
  #itinerary .itinerary_split .detailsWrapper2 h2 {
    line-height: 35px;
  }
}

#booking h1 {
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 30px;
  line-height: 80px;
  font-weight: 400;
  color: #1d1d1d;
  text-align: center;
}

#booking #mobileHead {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

#booking #mobileHead h1 {
  margin-left: 20px;
}

#booking .bookingSummary_content h1 {
  margin-bottom: 20px;
}

#booking .slider2 {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 0;
}

#booking .slider2 .checkout_slider {
  width: 100vw;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between !important;
  align-items: flex-start;
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

#booking .slider2 .checkout_slider img {
  width: 100vw;
  height: 200px;
  object-fit: cover;
  margin: 10px 0;
}

#booking .slider2 .checkout_slider .checkout_slider_content {
  height: 200px;
}

#booking .slider2 ul {
  text-align: center;
  position: relative;
  padding-left: 0;
  margin-top: 11px;
  margin-bottom: 0;
  font-size: 16px;
  z-index: 0;
}

#booking .slider2 .is-active::after {
  width: 8px;
  height: 8px;
  background-color: #235745ff;
  content: "";
  border-radius: 50%;
  display: block;
}

#booking .slider2 li::after {
  content: "";
  background-color: #ccc;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: block;
}

#booking .slider2 li {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 6px;
  color: #2a213e;
  text-align: -webkit-match-parent;
}

#booking .bookingSummary_content {
  width: 100vw;
  padding: 0 5vw;
  object-fit: contain;
  box-sizing: border-box;
}

#booking .bookingSummary_content .PromotionForm {
  font-size: 14px;
}

#booking .bookingSummary_content .PromotionForm .Form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

#booking .bookingSummary_content .PromotionForm .Form .promoInput {
  padding: 5px;
  border: 1px solid #eeeeee;
  width: 100px;
  margin: 0 10px;
}

#booking .bookingSummary_content .PromotionForm .Form .promoButton {
  color: #235745ff;
  border: none;
  background-color: white;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 600;
}

#booking .bookingSummary_content .PromotionForm .Form .promoButton:hover {
  cursor: pointer;
}

#booking .bookingSummary_content .PromotionForm p {
  margin-top: 5px;
}

#booking hr {
  width: 90vw;
  margin: 10px 0;
}

#booking h3 {
  color: #235745ff;
}

#booking .bookingSummary_overview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 90vw;
  margin: 10px 0;
}

#booking .bookingSummary_overview div {
  width: 90vw;
}

#booking .overview_details_heading p {
  font-size: 14px;
}

#booking .overview_details_data p {
  font-size: 18px;
  color: #235745ff;
  font-weight: 500;
}

#booking .overview_items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 90vw;
}

#booking .overview_items div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

#booking .overview_subtotal p {
  font-size: 14px;
  font-weight: 600;
}

#booking .overview_total {
  margin-bottom: 20px;
}

#booking .overview_total p {
  font-weight: 600;
}

#booking h4 {
  margin: 20px 0 20px 0;
  color: #636366;
}

#booking .bookingSummary_included h3 {
  margin-top: 30px;
  margin-bottom: 10px;
}

#booking .bookingSummary_included p {
  margin: 5px 0;
}

#booking #mobileDivider {
  width: 90vw;
  margin: 40px 0 !important;
}

#booking .bookingSummary_button {
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-items: center;
}

#booking .bookingSummary_button button {
  min-width: 130px;
  color: white;
  background-color: #235745ff;
  padding: 10px 10px;
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin: 10px 10px;
  margin: 0 0 !important;
}

#booking .bookingSummary_button button:hover {
  cursor: pointer;
}

#booking .bookingSummary_button .ErrorMessage {
  margin-bottom: 10px;
}

#booking .itinerary {
  margin: 20px 0 40px 0;
}

#booking .overview_four {
  padding-left: 20px;
  width: auto !important;
}

#booking .footnote {
  font-size: 12px;
  color: #636366;
  padding: 20px 20px;
  margin: 40px 10px 20px 10px;
}

#booking .bookingWithStripe {
  margin: 0 5vw;
  padding-bottom: 20px;
}

#booking .bookingWithStripe .StripeElement {
  margin: 20px 0;
  font-family: Poppins, sans-serif;
}

#booking .bookingWithStripe .StripeElement input {
  font-family: Poppins, sans-serif;
}

#booking .bookingWithStripe form {
  font-family: Poppins, sans-serif;
}

#booking .bookingWithStripe .SubmitButton {
  min-width: 130px;
  color: white;
  background-color: #235745ff;
  padding: 10px 10px;
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin: 10px 10px;
  margin: 0 0 !important;
}

#booking .bookingWithStripe .SubmitButton:hover {
  cursor: pointer;
}

#booking .bookingWithStripe .FormGroup {
  border: none;
}

#booking .bookingWithStripe .FormGroup2 {
  margin: 10px 0 20px 0;
}

#booking .bookingWithStripe .FormGroup2 label {
  color: #235745ff;
}

#booking .bookingWithStripe .FormGroup2 .FormRow {
  border-bottom: 1px solid #636366;
}

#booking .bookingWithStripe .FormGroup1 .FormRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 90vw;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border-bottom: 1px solid #636366;
  margin: 10px 0;
}

#booking .bookingWithStripe .FormGroup1 .FormRowInput {
  width: 90vw;
  border: none;
  padding: 10px 0;
}

#booking .bookingWithStripe .FormGroup1 input:focus {
  outline: none;
}

#booking .bookingWithStripe .FormGroup1 .FormRowLabel {
  color: #235745ff;
  margin: 10px 0;
}

#booking .bookingWithStripe .FormGroup3 {
  margin: 10px 0 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

#booking .bookingWithStripe .FormGroup3 .FormRowInput {
  width: 10px !important;
  margin-right: 10px;
}

#booking .bookingError {
  font-size: 14px;
  color: #235745ff;
  padding: 20px 0px;
  text-align: right;
}

@media (min-width: 700px) {
  #booking {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  #booking .logo_fullScreen {
    font-family: Georgia, Times, 'Times New Roman', serif;
    font-size: 40px !important;
    line-height: 60px;
    font-weight: 500;
    color: #1d1d1d !important;
    padding: 20px 0 20px 0 !important;
    background-color: white;
    box-shadow: 0 0 5px 0 #6363669a;
    text-align: center;
    width: 100vw;
  }
  #booking .bookingSummary_content {
    padding: 0 100px !important;
  }
  #booking .bookingSummary_content .PromotionForm {
    width: 96%;
    padding: 0 20px;
  }
  #booking hr {
    width: 100%;
  }
  #booking #mobileDivider {
    width: 50vw !important;
  }
  #booking #mobileDivider hr {
    width: 30% !important;
  }
  #booking .bookingSummary_overview {
    width: 96%;
    padding: 0 20px;
  }
  #booking .bookingSummary_button {
    width: 100% !important;
  }
  #booking .overview_items div {
    width: 100%;
  }
  #booking .footnote {
    padding: 20px 0;
    margin: 40px 0 20px 0;
  }
  #booking .bookingSummary_included p {
    padding: 0 0 0 20px;
  }
  #booking .slider2 .checkout_slider {
    height: 500px;
    width: 1000px;
  }
  #booking .slider2 .checkout_slider_content {
    height: 500px;
    width: 1000px;
  }
  #booking .slider2 .checkout_slider_content img {
    height: 496px;
    width: 1000px;
    object-fit: cover;
  }
  #booking .bookingWithStripe {
    width: 480px !important;
  }
  #booking .bookingWithStripe .FormRow {
    width: 100% !important;
  }
  #booking .bookingWithStripe .FormRowInput {
    width: 100% !important;
  }
  #booking .bookingWithStripe .FormGroup3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
  }
  #booking .bookingWithStripe .FormGroup3 .FormRow {
    width: 20px !important;
  }
  #booking .bookingWithStripe .FormGroup3 .FormRowInput {
    width: 10px !important;
    margin-right: 10px;
  }
  #booking .bookingWithStripe .bookingSummary_button {
    align-items: center;
  }
}

.saveChanges {
  cursor: pointer;
  min-width: 130px;
  color: white;
  background-color: #235745ff;
  padding: 10px 10px;
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin: 10px 10px;
}

.saveChanges:hover {
  cursor: pointer;
}

.clearButton {
  background-color: transparent;
  border: 0;
  color: #4a90e2;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  margin: 10px 10px;
}

#admin {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

#controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 10vh;
  border-bottom: 1px solid #d0d0d3;
}

#controls #dateRange {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#mapContainer {
  display: flex;
  flex-direction: row;
  height: 90vh;
}

#map {
  width: 50%;
  height: 100%;
}

#map .marker img {
  position: absolute;
  transform: translate(-50%, -50%);
}

#map img:hover {
  cursor: pointer;
}

#sideBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
  overflow: scroll;
}

#sideBar #admin_tagging {
  height: 50%;
}

#sideBar #propertyImages, #sideBar #activityImages {
  margin-top: 10px;
  text-align: center;
}

#sideBar #propertyImages img, #sideBar #activityImages img {
  max-height: 35vh;
  width: 40vw;
  object-fit: contain;
}

#sideBar #propertyData .element, #sideBar #activityData .element {
  margin: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 500;
}

#sideBar #propertyData label, #sideBar #activityData label {
  display: flex;
  align-items: center;
  flex: 1 1;
}

#sideBar #propertyData input[type=text], #sideBar #propertyData textarea, #sideBar #activityData input[type=text], #sideBar #activityData textarea {
  margin-left: 10px;
  padding: 3px 5px;
  box-sizing: border-box;
  font-size: 16px;
  flex: 1 1;
}

#sideBar #propertyData a, #sideBar #activityData a {
  margin-left: 10px;
}

#admin_tagging {
  width: 100vw;
  /*   @include flex_center(column); */
  /*   justify-content: flex-start;
  align-content: flex-start; */
}

#admin_tagging .admin_tagging_goBack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 10vh;
  padding: 20px 0;
}

#admin_tagging .admin_tagging_goBack img {
  width: 20px;
  height: auto;
}

#admin_tagging .admin_tagging_content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100vw;
  height: 80vh;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_arrow {
  width: 10vw;
  height: 80vh;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_arrow img {
  width: 20px;
  height: auto;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details {
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between !important;
  align-items: flex-start;
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box {
  width: 80vw !important;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box .admin_tagging_content_details_box_img {
  width: 80vw !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  max-height: 60vh;
  margin-bottom: 10px;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box .admin_tagging_content_details_box_img img {
  max-width: 80vw;
  max-height: 50vh;
  object-fit: contain;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box .admin_tagging_content_details_box_tags {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 10px 0;
  width: 80vw;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box p {
  padding: 0 5px;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box h2, #admin_tagging .admin_tagging_content .admin_tagging_content_details_box h3 {
  padding: 5px 0;
}

#signup {
  text-align: center;
}

#signup button {
  min-width: 130px;
  color: white;
  background-color: #235745ff;
  padding: 10px 10px;
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin: 10px 10px;
  font-size: 20px;
}

#signup button:hover {
  cursor: pointer;
}

#signup .hide {
  transform: scale(0);
}

#signup h1 {
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 35px;
  line-height: 60px;
  font-weight: 500;
  color: #1d1d1d;
  width: 100%;
  text-align: center;
}

#signup h2 {
  text-align: center;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 25px;
  width: 100%;
  line-height: 60px;
  font-weight: 400;
  color: #1d1d1d;
}

#signup h3 {
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  color: #636366;
}

#signup h4 {
  font-size: 16px;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  font-weight: 500;
}

#signup h5 {
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  color: #636366;
}

#signup p {
  margin: 20px 10px;
}

#signup hr {
  width: 100%;
  background-color: #eeeeee;
  height: 1px;
  border: none;
}

#signup input:focus,
#signup button:focus {
  outline: none;
}

#signup .signup_desktop_allContent {
  top: -85px;
  position: relative;
}

#signup #cover h1 {
  color: #f1f1f1;
  font-family: IBM Plex sans;
  font-size: 48px;
}

#signup #cover .coverContent_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  height: 100vh;
  margin-top: 40px;
}

#signup #cover .coverContent_title h1 {
  margin-top: 80px;
  width: 700px;
}

#signup #cover .coverBtn {
  height: auto !important;
  margin-top: 40px;
}

#signup #cover .coverBtn button {
  background-color: rgba(255, 255, 255, 0);
  border: none;
}

#signup #waitlist h1 {
  color: #f1f1f1;
  font-size: 48px;
}

#signup #waitlist .waitlistContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 1500px;
  box-sizing: border-box;
}

#signup #waitlist .waitlistContent h1 {
  margin-top: 80px;
}

#signup #waitlist .waitlistContent h2 {
  margin: 20px 20px;
  text-align: center;
  padding: 0 0 !important;
  font-size: 38px !important;
}

#signup #waitlist .waitlistContent h3 {
  margin: 20px 20px;
  text-align: center;
  padding: 0 0 !important;
  font-size: 25px !important;
}

#signup #waitlist .waitlistContent p {
  font-family: Poppins, sans-serif;
  font-size: 25px;
  padding: 5px 5px;
  text-align: center;
  color: #636366;
}

#signup #waitlist .waitlistContainer p:first-child {
  margin: 30px 20px;
}

#signup #waitlist .waitlistContainer input {
  padding: 10px 10px;
  margin: 10px 10px;
  font-size: 20px;
  border: 3px solid #235745ff;
  width: 400px;
  box-sizing: border-box;
}

#signup #waitlist .waitlistContainer input::-webkit-input-placeholder {
  color: #636366;
}

#signup #waitlist .waitlistContainer input:-ms-input-placeholder {
  color: #636366;
}

#signup #waitlist .waitlistContainer input::-ms-input-placeholder {
  color: #636366;
}

#signup #waitlist .waitlistContainer input::placeholder {
  color: #636366;
}

#signup #waitlist .waitlistContainer form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#signup .coverContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 0 !important;
  background-image: linear-gradient(to bottom, #c7aaaa3d, #ffffff00) !important;
  height: 100vh !important;
}

#signup .signupFlow_container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#signup .signupFlow_container .signup_desktop_content {
  padding: 30px 5vw;
  max-width: 1190px;
}

@media (min-width: 701px) {
  #signup .signup_intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 30px 30px 15px 30px;
  }
  #signup .signup_intro .signup_intro_content {
    max-width: 1200px;
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  #signup .signup_intro .signup_intro_content .signup_intro_image {
    width: 500px;
    height: 440px;
    /*p:first-child {
            left: -170px;
            top: 100px;
            width: 300px !important;
          }

          p:last-child {
            right: -290px;
            bottom: 90px;
            font-size: 22px;
            width: 360px !important;
            padding: 15px 10px;
          }*/
  }
  #signup .signup_intro .signup_intro_content .signup_intro_image p {
    font-family: Georgia, Times, 'Times New Roman', serif;
    background-color: #f3f3f3;
    color: #1d1d1d;
    font-size: 24px;
    padding: 15px 15px;
    /*             box-shadow: 0 0 3px 1px #a89e9e3d; */
    position: relative;
    z-index: 3;
    border-radius: 2px;
  }
  #signup .signup_intro .signup_intro_content .signup_intro_image .img_overlay {
    height: 450px;
    width: 300px;
    box-shadow: 0 0 3px 1px #eeeeee;
  }
  #signup .signup_intro .signup_intro_content .signup_intro_image .img_overlay div {
    background-color: #c7aaaa3d;
    z-index: 2;
    height: 450px;
    width: 300px;
    position: relative;
    top: -101%;
  }
  #signup .signup_intro .signup_intro_content .signup_intro_image .img_overlay img {
    z-index: 1;
    height: 450px;
    width: 300px;
    border-radius: 3px;
    box-shadow: 0 0 3px 1px #eeeeee;
  }
  #signup .signup_intro .signup_intro_content p {
    font-family: Poppins, sans-serif;
    font-size: 25px;
    padding: 15px 15px;
    text-align: left;
    color: #636366;
  }
  #signup .signup_intro_content_footnote {
    font-family: Poppins, sans-serif;
    align-self: flex-end;
    color: #636366;
    font-size: 16px;
    margin-bottom: 0px;
  }
}

#signup .signup_desktop_outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

#signup .signup_termsofservice p {
  text-align: left;
}

#signup .signup_termsofservice .footer {
  position: relative !important;
  height: 200px;
  box-sizing: border-box;
  margin-top: 0 !important;
}

#signup .signup_termsofservice .coverContent_head {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100vw;
  padding: 30px 30px;
  margin: 0 !important;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
}

#signup .signup_termsofservice .coverContent_head h1 {
  font-size: 28px !important;
  text-align: left;
  width: auto;
  line-height: 10px;
  margin-top: 0 0 !important;
}

#signup .signup_termsofservice .coverContent_head p {
  margin: 0 !important;
  font-family: Poppins, sans-serif;
}

#signup .signup_termsofservice .coverContent_activeHead {
  box-shadow: 0 3px 20px #eeeeee, 0.2;
}

#signup .signup_desktop .footer {
  position: relative !important;
  height: 200px;
  box-sizing: border-box;
  margin-top: 0 !important;
}

#signup .signup_desktop .coverContent_head {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100vw;
  padding: 30px 30px;
  margin: 0 !important;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
}

#signup .signup_desktop .coverContent_head h1 {
  font-size: 28px !important;
  text-align: left;
  width: auto;
  line-height: 10px;
  margin-top: 0 0 !important;
}

#signup .signup_desktop .coverContent_head p {
  margin: 0 !important;
  font-family: Poppins, sans-serif;
}

#signup .signup_desktop .coverContent_activeHead {
  box-shadow: 0 3px 20px #eeeeee, 0.2;
}

#signup #signup_hiw {
  padding: 30px 0;
  max-width: 1290px;
  -ms-grid-row-align: center;
  align-self: center;
}

#signup #signup_hiw .signup_hiw_content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-items: flex-start;
  justify-content: space-evenly;
}

#signup #signup_hiw .signup_hiw_content h5 {
  height: 50px;
}

#signup #signup_hiw .signup_hiw_content .hiwBox {
  margin: 20px 20px;
  padding: 20px 20px;
  max-width: 250px;
  /*  height: 350px; */
  text-align: left;
}

#signup #signup_hiw .signup_hiw_content .hiwBox h2 {
  font-size: 20px !important;
  line-height: 30px;
  font-weight: 500;
  text-align: left;
}

#signup #signup_hiw .signup_hiw_content .hiwBox .hiwBox_img {
  flex: 2 1;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#signup #signup_hiw .signup_hiw_content .hiwBox .hiwBox_title {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

#signup #signup_hiw .signup_hiw_content .hiwBox img {
  width: 200px;
  object-fit: cover;
}

#signup #signup_hiw .signup_hiw_content .hiwBox .img_last_child {
  width: 150px !important;
}

#signup #signup_hiw .signup_hiw_content .hiwBox p {
  text-align: left;
}

#signup #signup_hiw .signup_hiw_content .hiwBox .hiwBox_underImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-items: flex-start;
  flex: 1 1;
  margin-top: 15px;
}

#signup #signup_hiw .signup_hiw_content .hiwBox .hiwBox_underImg .hiwBox_number {
  flex: 1 1;
  font-size: 30px;
  color: #235745ff;
  width: 100%;
  text-align: left;
}

#signup #signup_hiw .signup_hiw_content .hiwBox .hiwBox_underImg .hiwBox_content {
  flex: 10 1;
  box-sizing: border-box;
}

#signup #signup_hiw .signup_hiw_content .hiwBox .hiwBox_underImg .hiwBox_content p {
  margin: 10px 0 !important;
  font-family: Poppins, sans-serif;
  color: #1d1d1d;
}

#signup #signup_vibe .vibeContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  padding: 0 0;
  margin: 40px 0;
}

#signup #signup_vibe .vibeCard,
#signup #signup_vibe .vibeCardSelected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 250px;
  height: 350px;
  background-color: white;
  margin: 8px;
  border: 1px solid #eeeeee;
  box-shadow: 0 0 3px 1px #eeeeee;
}

#signup #signup_vibe .vibeCard img,
#signup #signup_vibe .vibeCardSelected img {
  width: 80%;
  height: 230px;
  object-fit: cover;
  margin-top: 23px;
}

#signup #signup_vibe .vibeCard .vibeCard_description,
#signup #signup_vibe .vibeCardSelected .vibeCard_description {
  height: 100px;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#signup #signup_vibe .vibeCard .vibeCard_description p,
#signup #signup_vibe .vibeCardSelected .vibeCard_description p {
  font-size: 18px;
  text-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  align-content: center;
  font-family: "IBM Plex Sans";
  text-transform: uppercase;
  font-weight: 400;
  color: #636366;
}

@media (min-width: 700px) {
  #signup #signup_vibe .vibeCard:first-child,
  #signup #signup_vibe .vibeCardSelected:first-child {
    margin-left: 0;
  }
  #signup #signup_vibe .vibeCard:last-child,
  #signup #signup_vibe .vibeCardSelected:last-child {
    margin-right: 0;
  }
}

#signup #signup_vibe .vibeCardSelected {
  box-shadow: 0 0 5px 2px #eeeeee;
}

#signup #signup_vibe .vibeCardSelected p {
  font-weight: 600;
  color: #1d1d1d !important;
  text-decoration: underline;
}

#signup #signup_vibe .vibeCard:hover {
  -webkit-animation: hover 1s 1 ease-in-out;
          animation: hover 1s 1 ease-in-out;
}

@-webkit-keyframes hover {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes hover {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

#signup #signup_prices .pricesSlider {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 500px;
  margin-bottom: 40px;
}

#signup #signup_prices .pricesSlider p:first-child {
  font-size: 18px;
  margin-bottom: 60px !important;
}

#signup #signup_prices .MultiSlider--root {
  margin: 20px 0 !important;
}

#signup #signup_prices .MuiSlider-markLabel {
  margin: 20px 0 !important;
  font-size: 17px !important;
}

#signup #signup_calendar .calendarContainer p {
  font-family: "IBM Plex sans";
  margin: 40px 10px 30px 10px;
}

#signup #signup_calendar .selectWeekends {
  margin-bottom: 30px;
}

#signup #register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  height: 20vh;
  margin-top: 85px;
}

#signup #register button {
  min-width: 130px;
  color: white;
  background-color: #235745ff;
  padding: 10px 10px;
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin: 10px 10px;
  font-size: 23px;
  padding: 20px 20px;
  min-width: 200px;
  margin-top: 50px;
}

#signup #register button:hover {
  cursor: pointer;
}

#signup #register h2 {
  max-width: 1000px;
}

#signup .signupFlow_container .fade-in {
  opacity: 0;
  transform: translateY(100vh);
}

#signup .signupFlow_container .fade-back-in {
  opacity: 0;
  transform: translateY(-100vh);
}

#signup .signupFlow_container .visible {
  transition: opacity 2s ease-in-out;
  opacity: 1;
  transform: translateY(0);
  transition: transform 1.3s ease-in-out;
}

#signup .signupFlow_container .fade-out {
  transform: translateY(-100vh) !important;
  transition: transform 1.3s ease-in-out;
}

#signup .signupFlow_container .fade-back {
  transform: translateY(100vh) !important;
  transition: transform 1.3s ease-in-out;
}

#signup .allSetContent img {
  width: 450px;
  max-height: 100%;
  object-fit: cover;
  margin: 20px 0;
}

#signup .allSetContent p {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  padding: 0px 15px;
  color: #636366;
}

#signup .footer {
  position: absolute;
  bottom: 0;
  width: 100vw;
  background-color: #eeeeee;
  margin-top: 40px;
  height: 80px;
  color: #1d1d1d;
}

#signup .footer p {
  margin: 0 !important;
}

#signup .allSetContent {
  margin-bottom: 40px;
}

#signup .signinContainer p:first-child {
  margin: 30px 20px;
}

#signup .signinContainer input {
  padding: 20px 20px;
  margin: 20px 20px;
  font-size: 16px;
  border: 1px solid #eeeeee;
  width: 200px;
  box-sizing: border-box;
}

#signup .signinContainer input::-webkit-input-placeholder {
  color: #636366;
}

#signup .signinContainer input:-ms-input-placeholder {
  color: #636366;
}

#signup .signinContainer input::-ms-input-placeholder {
  color: #636366;
}

#signup .signinContainer input::placeholder {
  color: #636366;
}

#signup .signinContainer form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#signup .signinContainer #submitEmail {
  padding: 20px 20px;
  margin: 20px 20px 10px 20px;
  width: 200px;
}

.signupFlow {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.signupFlow .signupFlow_header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100vw;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  padding: 35px 30px;
  box-sizing: border-box;
  z-index: 5;
  background-color: white;
}

.signupFlow .signupFlow_header h1 {
  text-align: left !important;
  font-size: 28px !important;
  line-height: 10px !important;
}

.signupFlow .back {
  width: 30px;
  position: absolute;
  top: 2px;
  right: 2px;
}

@media (min-height: 1000px) {
  .signupFlow .back {
    width: 30px;
    position: absolute;
    top: 2px;
    left: 49%;
    right: 49%;
  }
  .signupFlow.footer {
    height: 150px;
  }
}

/* ==================================================

                Changes for Mobile

 ====================================================*/
@media (max-width: 700px) {
  #signup #cover .coverContent_title {
    max-width: 400px !important;
  }
  #signup #cover .coverContent_title h1 {
    font-size: 30px !important;
    width: 100vw;
    line-height: 40px;
    text-shadow: 0px 0px 1px #d3d2d2;
  }
  #signup #waitlist h1 {
    color: #f1f1f1;
    font-size: 20px;
  }
  #signup #waitlist .waitlistContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    max-width: 700px;
    box-sizing: border-box;
  }
  #signup #waitlist .waitlistContent h1 {
    margin-top: 80px;
  }
  #signup #waitlist .waitlistContent h2 {
    max-width: 300px;
    font-size: 20px !important;
  }
  #signup #waitlist .waitlistContent h3 {
    margin: 20px 20px;
    text-align: center;
    padding: 0 0 !important;
    font-size: 25px !important;
  }
  #signup #waitlist .waitlistContent p {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    color: #636366;
    width: 85%;
    margin-top: 0 !important;
  }
  #signup #waitlist .waitlistContainer p:first-child {
    margin: 30px 20px;
  }
  #signup #waitlist .waitlistContainer input {
    padding: 10px 10px;
    margin: 10px 10px;
    font-size: 15px;
    border: 3px solid #235745ff;
    width: 250px;
    box-sizing: border-box;
  }
  #signup #waitlist .waitlistContainer input::-webkit-input-placeholder {
    color: #636366;
  }
  #signup #waitlist .waitlistContainer input:-ms-input-placeholder {
    color: #636366;
  }
  #signup #waitlist .waitlistContainer input::-ms-input-placeholder {
    color: #636366;
  }
  #signup #waitlist .waitlistContainer input::placeholder {
    color: #636366;
  }
  #signup #waitlist .waitlistContainer form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  #signup #waitlist .waitlistContainer button {
    font-size: 18px !important;
  }
  #register h2 {
    font-size: 23px;
  }
  #register button {
    font-size: 20px !important;
  }
  #signup_hiw .signup_hiw_content {
    flex-flow: wrap;
  }
  #feat1 {
    max-width: 100vw !important;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  #feat2 {
    max-width: 100vw !important;
    margin: 30px 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .signup_intro_content {
    max-width: 100vw !important;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .signup_intro_content .signup_intro_image {
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .signup_intro_content .signup_intro_image p {
    font-family: Georgia, Times, 'Times New Roman', serif;
    color: #1d1d1d;
    font-size: 16px;
    padding: 10px 10px;
    z-index: 3;
    border-radius: 2px;
  }
  .signup_intro_content .signup_intro_image p:first-child {
    font-size: 22px;
  }
  .signup_intro_content .signup_intro_image p:last-child {
    background-color: #f3f3f3;
    font-size: 16px;
    width: 80% !important;
    margin: 20px 0 !important;
  }
  .signup_intro_content .signup_intro_image .img_overlay {
    height: 300px;
    width: 200px;
    box-shadow: 0 0 3px 1px #eeeeee;
  }
  .signup_intro_content .signup_intro_image .img_overlay div {
    background-color: #c7aaaa3d;
    z-index: 2;
    height: 300px;
    width: 200px;
    position: relative;
    top: -101%;
  }
  .signup_intro_content .signup_intro_image .img_overlay img {
    z-index: 1;
    height: 300px;
    width: 200px;
    border-radius: 3px;
    box-shadow: 0 0 3px 1px #eeeeee;
  }
  .signup_intro_content p {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    color: #636366;
    width: 85%;
    margin-top: 0 !important;
  }
  .signupFlow_header {
    box-shadow: 0 0 3px 2.5px #eeeeee;
  }
  .signupFlow {
    justify-content: flex-start;
  }
  .signupFlow .back {
    width: 20px;
  }
  #signup .signupFlow_container .signup_desktop_content {
    padding: 30px 20px 10px 20px !important;
  }
  #signup_vibe, #signup_calendar, #signup_prices, .signinContainer {
    margin-top: 90px !important;
  }
  #signup_vibe h1, #signup_calendar h1, #signup_prices h1, .signinContainer h1 {
    font-size: 26px !important;
    line-height: 40px !important;
  }
  .allSetContainer h1 {
    margin-top: 120px;
    font-size: 26px !important;
    line-height: 40px !important;
  }
  .allSetContent img {
    width: 100vw !important;
  }
  #signup .allSetContent p {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    margin: 0px 15px;
    color: #636366;
  }
  .pricesSlider {
    width: 80vw !important;
  }
  #signup_vibe .vibeContainer {
    flex-direction: column !important;
    margin: 20px 0 !important;
  }
  #signup_vibe .vibeContainer .vibeCard, #signup_vibe .vibeContainer .vibeCardSelected {
    flex-direction: row !important;
    height: 13vh !important;
    width: 300px !important;
  }
  #signup_vibe .vibeContainer .vibeCard img, #signup_vibe .vibeContainer .vibeCardSelected img {
    height: 11vh !important;
    width: 40% !important;
    margin-top: 0 !important;
    margin-left: 10px;
  }
  #signup_vibe .vibeContainer .vibeCard .vibeCard_description, #signup_vibe .vibeContainer .vibeCardSelected .vibeCard_description {
    height: 18vh;
    width: 50%;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  #signup_vibe .vibeContainer .vibeCard .vibeCard_description p, #signup_vibe .vibeContainer .vibeCardSelected .vibeCard_description p {
    font-size: 18px;
    text-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    align-content: center;
    font-family: "IBM Plex Sans";
    text-transform: uppercase;
    font-weight: 400;
    color: #636366;
  }
}

#favorites {
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

#favorites p {
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

#favorites h1 {
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 30px;
  line-height: 60px;
  font-weight: 500;
  color: #1d1d1d;
}

#favorites .desktopWrapper {
  margin: auto;
  max-width: 1320px;
}

#favorites_content .title h1 {
  font-size: 38px;
  margin: 36px 20px 16px 20px;
}

#favorites_content .favsCards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 36px;
}

#favorites_content .favsCards .favsCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 398px;
  height: 298px;
  background-color: white;
  border: 1px solid #eeeeee;
  box-shadow: 0 0 3px 1px #eeeeee;
  margin: 20px;
  position: relative;
}

#favorites_content .favsCards .favsCard .cardLink {
  display: contents;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: black;
}

#favorites_content .favsCards .favsCard .heartImage {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 7px;
  left: 10px;
}

#favorites_content .favsCards .favsCard .favsCardImages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 60%;
}

#favorites_content .favsCards .favsCard .favsCardImages img {
  width: 60%;
  height: 100%;
  object-fit: cover;
  padding-right: 4px;
}

#favorites_content .favsCards .favsCard .favsCardImages .favsCardImagesVertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 40%;
  height: 100%;
}

#favorites_content .favsCards .favsCard .favsCardImages .favsCardImagesVertical .topImage {
  width: 100%;
  height: calc(50% - 2px);
  padding-bottom: 2px;
}

#favorites_content .favsCards .favsCard .favsCardImages .favsCardImagesVertical .topImage img {
  width: 100%;
  padding-right: 0;
  object-fit: cover;
}

#favorites_content .favsCards .favsCard .favsCardImages .favsCardImagesVertical .bottomImage {
  width: 100%;
  height: calc(50% - 2px);
  padding-top: 2px;
}

#favorites_content .favsCards .favsCard .favsCardImages .favsCardImagesVertical .bottomImage img {
  width: 100%;
  padding-right: 0;
  object-fit: cover;
}

#favorites_content .favsCards .favsCard .favsCardContent {
  height: 40%;
  width: calc(100% - 30px);
  padding: 20px 15px;
}

#favorites_content .favsCards .favsCard .favsCardContent h1 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #1d1d1d;
}

#favorites_content .favsCards .favsCard .favsCardContent .favsCardDetails {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

#favorites_content .favsCards .favsCard .favsCardContent .favsCardDetails p {
  font-size: 12px;
  padding-right: 10px;
  color: #636366;
}

#favorites_content .favsCards .favsCard .favsCardContent .favsCardPrice {
  font-size: 14px;
  margin-top: 10px;
}

/* Media Settings */
/* Colors */
/* ================================================================================
                                Cover Settings
================================================================================*/
#cover {
  background-size: cover;
  width: 100vw;
  height: 100vh;
  text-align: center;
  /*   margin: 0;
  padding: 0;
  position: absolute;
  top: 0%;  */
  /* Add some content at the bottom of the video/page */
  /*   .subHeader {
    font-size: 22px;
  } */
}

#cover #coverVideo {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#cover .coverContent {
  position: relative;
  min-width: 100vw;
  min-height: 30vh;
  top: -101%;
  background: none;
  color: #f1f1f1;
  padding: 30vh 0;
  text-align: center;
  z-index: 1;
}

#cover h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 133px;
  line-height: 120px;
  font-weight: 400;
}

#cover h2 {
  font-family: Poppins, sans-serif;
  color: rgba(255, 255, 255, 0.63);
  font-size: 26px;
  line-height: 30px;
  padding: 0 15vw;
}

#cover .coverContent_old_h2 {
  font-size: 26px !important;
  line-height: 60px;
}

@media (max-width: 640px) {
  #cover h1 {
    font-size: 80px;
    line-height: 80px;
  }
  #cover h2 {
    font-size: 20px !important;
    padding: 0 5px;
  }
  #cover .coverContent_old_h2 {
    font-size: 20px !important;
    line-height: 40px;
  }
}

#cover .coverBtn {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 50px;
  /*     button {
      background-color: map-get($colors, cover-btn);
      border: 0.5px solid map-get($colors, cover-btn);
      border-radius: 3px;
      color: white;
      padding: 8px 8px;
      margin: 3px 15px;
      font-size: 20px;
    } */
  /*     button:hover {
      background-color: map-get($colors, taupe);
    } */
}

#cover .coverBtn button:focus {
  outline: none;
}

#cover .coverBtn img {
  margin: 10px 0;
  width: 50px;
  height: 50px;
  /*       background-color: map-get($colors, cover-btn); */
  background-color: rgba(255, 255, 255, 0.815);
  border-radius: 50%;
}

#cover .coverBtn img:hover {
  -webkit-animation: hover-pulse 2s 1 ease-out;
          animation: hover-pulse 2s 1 ease-out;
}

@-webkit-keyframes hover-pulse {
  0% {
    transform: scale(1);
    background-color: white;
  }
  50% {
    transform: scale(1.2);
    background-color: white;
  }
  100% {
    transform: scale(1);
    background-color: white;
  }
}

@keyframes hover-pulse {
  0% {
    transform: scale(1);
    background-color: white;
  }
  50% {
    transform: scale(1.2);
    background-color: white;
  }
  100% {
    transform: scale(1);
    background-color: white;
  }
}

/* ================================================================================
                                Inspiration Settings
================================================================================*/
#inspiration {
  width: 100vw;
  height: auto;
  padding-bottom: 30px;
  border-bottom: 1px solid #dee2e6;
}

#inspiration h1 {
  font-family: Poppins, sans-serif;
  color: #10151a;
  text-align: center;
  font-size: 26px;
  line-height: 30px;
  padding: 10px 20px;
  margin: 40px 0 40px 0;
}

@media (max-width: 940px) {
  #inspiration h1 {
    font-size: 23px !important;
    margin: 25px 0 20px 0 !important;
  }
}

#inspiration .inspirationContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  /*  @keyframes hover-insp {
      0% {
        transform: scale(1.0);
     
      }
      100% {
        transform: scale(1.05);
      
      } 
    } */
}

@media (min-width: 940px) {
  #inspiration .inspirationContainer {
    flex-flow: row wrap;
    width: 100vw;
  }
}

@media (max-width: 940px) {
  #inspiration .inspirationContainer {
    flex-flow: row unwrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    height: auto;
    padding: 5px 0 10px 20px;
    justify-content: flex-start;
    align-items: space-between;
  }
}

#inspiration .inspirationContainer .inspirationCard:hover {
  /*       border: 2px solid map-get($colors, hover) !important;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important; */
  box-shadow: #53534d 0px 2px 8px !important;
  border: none;
  /*       animation: hover-insp 1.1s ease-out infinite; */
}

#inspiration .inspirationContainer .inspirationCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center !important;
  /* border: 1px solid rgba(0, 0, 0, 0.02) !important; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  border-radius: 5px !important;
  max-width: 33%;
  margin-bottom: 30px;
}

#inspiration .inspirationContainer .inspirationCard .inspirationImg {
  width: 15vw;
  height: 15vw;
  padding: 0 0;
  margin: 0 0;
}

#inspiration .inspirationContainer .inspirationCard img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border-top-left-radius: 5px;
  object-fit: cover;
}

@media (min-width: 940px) {
  #inspiration .inspirationContainer .inspirationCard .inspirationImg img {
    border-bottom-left-radius: 5px;
  }
}

#inspiration .inspirationContainer .inspirationCard .inspirationText {
  width: 10vw;
  text-align: left;
  padding: 0 15px;
  font-family: Poppins, sans-serif;
  color: black;
  font-size: 22px;
  margin: 0 0 !important;
}

#inspiration .inspirationContainer .inspirationCard .inspirationText p {
  margin: 0 0;
}

@media (max-width: 940px) {
  #inspiration .inspirationContainer .inspirationCard {
    flex-flow: column wrap;
    height: 35vh;
    align-items: flex-start !important;
    justify-content: space-between !important;
    min-width: 150px !important;
    margin: 0 10px 0 0;
  }
  #inspiration .inspirationContainer .inspirationCard .inspirationImg {
    width: 100%;
    height: 60%;
    padding: 0 0;
    margin: 0 0;
  }
  #inspiration .inspirationContainer .inspirationCard img {
    width: 100% !important;
    height: 100% !important;
    padding: 0;
    margin: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    object-fit: cover;
  }
  #inspiration .inspirationContainer .inspirationCard .inspirationText {
    font-size: 18px;
    width: 100%;
    padding: 0 10px 0 0;
    margin: 0;
    height: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  #inspiration .inspirationContainer .inspirationCard .inspirationText p {
    padding: 5px 0 0 10px;
  }
}

#inspiration .multiSelect {
  text-align: center;
  margin-top: 30px;
  width: 100vw;
}

#inspiration .multiSelect img {
  width: 50px;
  height: 50px;
}

@media (max-width: 940px) {
  #inspiration .multiSelect img {
    width: 33px !important;
    height: 33px !important;
  }
}

#inspiration .reverseExpansion {
  transform: rotate(180deg);
}

/* ================================================================================
                                Comparison Settings
================================================================================*/
#comparisonContainer {
  width: 100vw;
  height: auto;
  margin: 0 0;
  padding: 0 0;
  display: flex;
  flex-direction: column;
  /* ==========================================================================
                       START - Comparison - Desktop new 
      ==========================================================================*/
  /* ==========================================================================
                       START - Comparison - Mobile Setting 
      ==========================================================================*/
  /* Comparison Mobile View */
}

#comparisonContainer .priceInfo {
  font-size: 14px;
  padding: 10px 20px 20px 20px;
  color: #615e5e;
  margin: 0 0;
}

#comparisonContainer h1 {
  font-family: Poppins, sans-serif;
  color: #10151a;
  text-align: center;
  font-size: 26px;
  line-height: 30px;
  padding: 10px 20px;
  margin: 40px 0 10px 0;
}

#comparisonContainer h2 {
  font-family: Poppins, sans-serif;
  color: #c0c4c9;
  text-align: center;
  font-size: 16px !important;
  line-height: 25px;
  padding: 0 20px;
  margin: 0;
}

@media (max-width: 940px) {
  #comparisonContainer h1 {
    font-size: 23px !important;
    margin: 25px 0 0 0 !important;
  }
  #comparisonContainer h2 {
    font-size: 13px;
  }
}

#comparisonContainer button {
  border: 0.5px solid #dee2e688;
  border-radius: 3px;
  color: #10151a;
  padding: 5px 8px;
  margin: 3px 0px;
  font-size: 15px;
}

#comparisonContainer button:focus {
  outline: none;
}

#comparisonContainer .filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw !important;
  font-family: Poppins, sans-serif;
  color: #10151a;
  font-size: 15px;
  min-height: 45px;
}

#comparisonContainer .filter .filterSearch {
  margin: 0 0;
  padding: 0 0;
  width: 50%;
  display: flex;
  justify-content: flex-start;
}

#comparisonContainer .filter button {
  background-color: #dee2e688;
}

#comparisonContainer .filter input {
  border: 0.5px solid #dee2e688;
  border-radius: 3px;
  padding: 0 20px;
  font-size: 15px;
  min-width: 300px;
}

#comparisonContainer .filter .filterDetails {
  margin: 0 0;
  padding: 0 20px 0 0;
  width: 40%;
  display: flex;
  justify-content: space-between !important;
  align-content: space-between;
}

#comparisonContainer .comparison {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#comparisonContainer .comparison .initialView {
  display: flex;
  justify-content: flex-start;
  padding: 0 0 0 0;
  /* ==========================================================================
                       START - Old setting - boxes underneath 
      ==========================================================================*/
  /*         @media(min-width: map-get($breakpoints,flex-change)) {
          .comparison {
            height: 80vh;
          }

          .initialView {
            flex-direction: row;
            height: 30vh !important;
          }

          .scenerieBox{
            height: 25vh;
            width: 90%;
          }

          .catchPhrase {
            width: 30%;
            padding: 5px 10px 0 20px;
          }

          .moreDetails {
            border-left: 1px solid map-get($colors, cover-btn-border) !important;
          }

          .imgSlider {
            padding: 0 0;
            margin: 0 0;
            width: 50vw;
            height: auto;
          }

          .imgSlider1 {
            position: absolute;
            width: 50%;
            object-fit: cover;
          }

          .imgSlider img {
            border-bottom-left-radius: 5px;
            width: 50vw;
            height: 25vh;
            object-fit: cover;
            border-top-left-radius: 5px;
          }

          .imgSlider2 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 50vw;
            height: 25vh;
            position: absolute;
          }

          .slideForwards {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0 10px;
              color: white;
              width: 25vw;
              height: 25vh;
              margin: 0 0;
          }

          .slideArrow {
            width: 20px !important;
            height: 20px !important;
          }

          .slideBack {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 10px;
              color: white;
              width: 25vw;
              height: 25vh;
              margin: 0 0;
          }
        }
 */
  /* ==========================================================================
                        END -  Old setting - boxes underneath  
      ==========================================================================*/
}

#comparisonContainer .comparison .initialView .scenerieBox:hover {
  box-shadow: #70706b 0px 2px 8px !important;
  border: none;
}

#comparisonContainer .comparison .initialView .scenerieBox {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: auto;
  margin: 20px 0;
  border: 1px solid rgba(0, 0, 0, 0.02) !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  border-radius: 5px !important;
  /*         .imgSlider {
          padding: 0 0;
          margin: 0 0;
          width: 50vw;
          height: auto;
        }

        img {
          margin: 0 0;
          padding: 0 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 5px;
        } */
}

#comparisonContainer .comparison .initialView .scenerieHeader {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 20px 10px;
  font-size: 20px;
  /* color: map-get($colors, taupe) */
  color: #183041;
}

#comparisonContainer .comparison .initialView .extendedCtn {
  padding: 8px 10px 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  font-size: 13px;
  border-top: 0.5px solid #dee2e6;
}

#comparisonContainer .comparison .initialView .extendedFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: space-evenly;
  align-items: center;
}

#comparisonContainer .comparison .initialView .extendedFlex img {
  width: 20px;
  height: 20px;
  padding: 0 5px 0 0;
}

#comparisonContainer .comparison .initialView .reverseExpansion {
  transform: rotate(180deg);
}

#comparisonContainer .comparison .initialView .moreDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40px;
  height: auto;
  text-align: center;
  font-weight: 800px;
  color: #b1b4b8;
}

#comparisonContainer .comparison .initialView .moreDetails p img {
  width: 20px;
  height: 20px;
}

#comparisonContainer .comparison .initialView .catchPhrase {
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  font-weight: 300;
}

#comparisonContainer .comparison .initialView .catchPhrase h3 {
  color: #7f8183;
  font-size: 20px;
  text-align: center;
  padding: 10px 15px 0 15px;
  margin: 10px 0 0 0;
}

#comparisonContainer .comparison .initialView .catchPhrase p {
  padding: 15px 15px 0 15px !important;
  margin: 0 0 20px 0 !important;
  text-align: left;
  margin: auto;
  color: black !important;
  text-decoration: none !important;
  -webkit-text-decoration-style: none !important;
          text-decoration-style: none !important;
}

#comparisonContainer .comparison .initialView .priceAndShare {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  padding: 0 10px 0 10px;
  /* width: 100%; */
  height: 20% !important;
}

#comparisonContainer .comparison .initialView .priceAndShare p {
  color: black;
  font-size: 15px;
}

#comparisonContainer .comparison .initialView .priceAndShare .share-btn {
  background-color: #dee2e688;
}

#comparisonContainer .comparison .initialView .priceAndShare .book-btn {
  background-color: #183041;
  font-size: 16px;
  padding: 10px 10px;
  color: white;
}

#comparisonContainer .comparison .initialView .priceAndShare .book-btn:hover {
  background-color: #615e5e;
}

#comparisonContainer .comparison .initialView .priceAndShare .proceed-btns {
  justify-content: center;
  width: auto;
  align-items: center;
  align-content: center;
  margin: 0 0;
}

#comparisonContainer .comparison .initialView .priceAndShare .e-mail {
  width: 100%;
  font-size: 15px;
}

#comparisonContainer .comparison .initialView .priceAndShare .e-mail form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  text-align: center;
  align-items: center;
}

#comparisonContainer .comparison .initialView .priceAndShare .e-mail input {
  text-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  width: 80%;
  border: 0.5px solid #dee2e688;
  border-radius: 3px;
  padding: 0 10px;
  font-size: 15px;
  height: 40px;
}

#comparisonContainer .comparison .initialView .slideArrow {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  /*               border: 1px solid white; */
  padding: 10px 10px 10px 10px;
}

#comparisonContainer .comparison .initialView .slideArrowAnimation {
  -webkit-animation: hover-slide 2s 1 ease-out;
          animation: hover-slide 2s 1 ease-out;
}

@-webkit-keyframes hover-slide {
  0% {
    transform: scale(1);
    background-color: rgba(255, 255, 255, 0.61);
  }
  50% {
    transform: scale(1.5);
    background-color: rgba(255, 255, 255, 0.61);
  }
  100% {
    transform: scale(1);
    background-color: none;
  }
}

@keyframes hover-slide {
  0% {
    transform: scale(1);
    background-color: rgba(255, 255, 255, 0.61);
  }
  50% {
    transform: scale(1.5);
    background-color: rgba(255, 255, 255, 0.61);
  }
  100% {
    transform: scale(1);
    background-color: none;
  }
}

@media (min-width: 940px) {
  #comparisonContainer {
    /* Comparison Initial View */
  }
  #comparisonContainer .comparison {
    width: auto;
    height: auto;
    flex-direction: row;
    padding: 5px 20px 0 20px;
    justify-content: space-between;
  }
  #comparisonContainer .filter {
    height: 30px;
    max-width: 100vw;
    padding: 0 0px 0 0px;
  }
  #comparisonContainer .filter input {
    padding: 0 10px;
    font-size: 13px;
    min-width: 50px;
    margin: 0 0 0 20px;
  }
  #comparisonContainer .filter .filterDetails {
    width: 40%;
    display: flex;
    justify-content: space-between !important;
    align-content: center;
    align-items: center;
    padding: 0 0;
  }
  #comparisonContainer .ppl-btn {
    margin: 0 20px 0 0;
  }
  #comparisonContainer .initialView {
    flex-direction: column !important;
    padding: 0 0 10px 0px;
    max-width: 33%;
    height: 100%;
  }
  #comparisonContainer .scenerieBox {
    flex-direction: column !important;
    width: 30vw !important;
    height: auto;
  }
  #comparisonContainer .imgSlider {
    width: 30vw !important;
    height: 40vh !important;
    padding: 0 0;
    margin: 0 0;
    display: flex;
  }
  #comparisonContainer .imgSlider1 {
    width: 30vw !important;
    height: 40vh !important;
    object-fit: cover;
    z-index: -1;
    flex: 1 1;
  }
  #comparisonContainer .imgSlider1 img {
    width: 30vw !important;
    height: 40vh !important;
    /*               border-top-right-radius: 5px;
              border-top-left-radius: 5px; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
    border-radius: 2px;
    object-fit: cover;
    padding: 0 0;
    margin: 0 0;
    z-index: 2;
    flex: 1 1;
  }
  #comparisonContainer .imgSlider2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 30vw !important;
    height: 40vh !important;
    z-index: 3;
    flex: 0 1;
    left: -100%;
    position: relative;
  }
  #comparisonContainer .slideForwards {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
    color: white;
    width: 15vw;
    height: 40vh !important;
    margin: 0 0;
  }
  #comparisonContainer .slideBack {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    color: white;
    width: 15vw;
    height: 40vh !important;
    margin: 0 0;
  }
  #comparisonContainer .catchPhrase {
    width: 30vw !important;
    /*               height: 15vh !important; */
    padding: 0 0 !important;
    /*               box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important; */
    border-radius: 2px;
  }
  #comparisonContainer .catchPhrase p {
    padding: 0 10px;
  }
  #comparisonContainer .moreDetails {
    width: 30vw !important;
    height: 40px !important;
    border-top: 1px solid #dee2e6 !important;
  }
  #comparisonContainer .priceAndShare {
    width: 30vw !important;
    height: 20% !important;
    padding: 0 0 5px 0 !important;
  }
}

@media (max-width: 940px) {
  #comparisonContainer {
    /* Filter Search Bar */
    /* Comparison Initial View */
  }
  #comparisonContainer .filter {
    height: 30px;
  }
  #comparisonContainer .filter input {
    padding: 0 10px;
    font-size: 13px;
    min-width: 50px;
    margin: 0 0 0 20px;
  }
  #comparisonContainer .filter .filterDetails {
    width: 40%;
    display: flex;
    justify-content: space-between !important;
    align-content: space-between;
    padding: 0 20px 0 0;
  }
  #comparisonContainer .filterDetails p {
    visibility: hidden;
    display: none;
  }
  #comparisonContainer .comparison {
    flex-flow: row unwrap;
    flex-direction: row;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    height: auto;
    padding: 5px 20px 0px 0px;
    justify-content: flex-start;
    align-content: space-between;
  }
  #comparisonContainer .initialView {
    flex-direction: column !important;
    padding: 0 0 10px 0px;
    margin: 0 20px;
    max-width: 100%;
    height: 100%;
  }
  #comparisonContainer .scenerieBox {
    flex-direction: column !important;
    width: 100% !important;
    height: auto;
  }
  #comparisonContainer .imgSlider {
    width: 80vw !important;
    height: 40vh !important;
    padding: 0 0;
    margin: 0 0;
    display: flex;
  }
  #comparisonContainer .imgSlider1 {
    width: 80vw !important;
    height: 40vh !important;
    object-fit: cover;
    z-index: -1;
    flex: 1 1;
  }
  #comparisonContainer .imgSlider1 img {
    width: 80vw !important;
    height: 40vh !important;
    /*       border-top-right-radius: 5px;
      border-top-left-radius: 5px; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
    object-fit: cover;
    padding: 0 0;
    margin: 0 0;
    z-index: 2;
    flex: 1 1;
  }
  #comparisonContainer .imgSlider2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80vw !important;
    height: 40vh !important;
    z-index: 0;
    flex: 1 1;
    left: -100%;
    position: relative;
  }
  #comparisonContainer .slideForwards {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
    color: white;
    width: 40vw;
    height: 40vh !important;
    margin: 0 0;
  }
  #comparisonContainer .slideArrow {
    width: 20px !important;
    height: 20px !important;
  }
  #comparisonContainer .slideBack {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    color: white;
    width: 40vw;
    height: 40vh !important;
    margin: 0 0;
  }
  #comparisonContainer .catchPhrase {
    /*       width: 80vw !important; */
    /*       height: 15vh !important; */
    padding: 0 0 !important;
    /*       box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important; */
  }
  #comparisonContainer .catchPhrase p {
    padding: 0 10px;
  }
  #comparisonContainer .moreDetails {
    width: 80vw !important;
    height: 40px !important;
    border-top: 1px solid #dee2e6 !important;
  }
  #comparisonContainer .priceAndShare {
    width: 100% !important;
    height: 20% !important;
    padding: 0 0 5px 0 !important;
  }
}

/* Email-Modal Setting */
.Modal {
  /*    border: 1px solid map-get($colors, 1); */
  right: auto;
  bottom: auto;
  left: auto;
  /*  margin: 0 5vw;  */
  padding: 0 0 20px 0;
  top: 50%;
  transform: translate(0%, 30%);
  /*  background-color: white; */
  z-index: 6;
  border-radius: 3px;
}

.Modal:focus {
  outline: none;
}

.ReactModal__Content {
  border-radius: 5px !important;
  height: auto !important;
  z-index: 6;
  background-color: white;
}

.ReactModal__Content--after-open {
  border-radius: 5px !important;
  border: 2px solid #183041 !important;
  height: auto !important;
  z-index: 6;
  background-color: white;
  margin: 0 5vw;
}

.ReactModal_Body--open {
  z-index: 6;
  flex: 6 1;
}

.ReactModal__Overlay--after-open {
  z-index: 6;
}

#EmailPopupHeader {
  padding: 0 10px;
  color: #183041;
}

.booking-flex {
  display: flex;
  flex-direction: row;
  flex-flow: unwrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.booking-flex button {
  margin: 0 10px 0 10px;
  background-color: #183041;
}

.booking-flex .orange-btn {
  background-color: #ff8800 !important;
  color: white;
  padding: 10px 12px !important;
  border-radius: 3px;
  font-size: 18px;
}

.bookingStep {
  border: 1px solid #183041;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 0 0 0 10px;
}

#emailForm {
  margin: 10px 10px 0 0;
  padding: 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#emailForm input {
  border-radius: 3px;
  border: 1px solid #cfdfda;
  min-width: 200px;
  padding: 18px 10px;
  margin: 0 10px 0 10px;
  font-size: 16px;
}

#emailForm input:focus {
  outline: none;
}

.book-modal {
  /*  background-color: map-get($colors, taupe); */
  /*     border: 1px solid map-get($colors, taupe); */
  color: white;
  /*     background-color: map-get($colors, 1); */
  /*     border: 1px solid map-get($colors, 1); */
  padding: 12px 12px;
  /*     margin: 20px 10px 0 0; */
  border-radius: 3px;
  font-size: 18px;
}

.book-modal:hover {
  background-color: #615e5e;
  border: 1px solid #615e5e;
}

.book-modal:focus {
  outline: none;
}

#close {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 10%;
  object-fit: contain;
  background-color: none;
  margin: 5px 0;
}

#close button {
  background-color: none !important;
}

#close button:focus {
  outline: none;
}

#close #exitModal {
  width: 20px;
  height: 20px;
  object-fit: contain;
  padding: 10px 10px;
}

.book-li {
  width: 100%;
  display: flex;
  flex-direction: center;
  justify-content: center;
  align-content: center;
  font-size: 18px;
}

.book-li button:hover {
  background-color: #276476;
  border: 1px solid #276476;
}

#submitMessage {
  padding: 0 10px;
}

#formBody {
  color: black;
  /* padding: 0 20px 0 0; */
  margin: 0 0 10px 0;
  font-size: 18px;
  /*     #submitEmail{
      background-color: map-get($colors, cover-btn);
      color: map-get($colors, dark-grey);
      border: 1px solid map-get($colors, cover-btn-border);
      padding: 10px 10px;
      border-radius: 3px;
      outline-style: none;
      font-size: 18px;
    } */
}

#formBody li {
  /*   padding: 0 20px 0 0; */
}

#formBody p {
  margin: 10px 10px;
}

#formBody #submitEmail:focus {
  outline: none;
}

@media (max-width: 940px) {
  #formBody .Modal {
    margin: 0 5vw !important;
  }
  #formBody .formBody p {
    margin: 0 0;
  }
  #formBody #emailForm {
    display: flex;
    flex-direction: column;
    max-width: 230px;
  }
  #formBody .booking-flex {
    align-items: flex-start;
    margin: 0 0 10px 0;
  }
  #formBody .booking-flex .partner {
    margin-top: 0 !important;
    font-size: 18px;
    padding: 10px 0;
    margin-left: 0;
  }
  #formBody input {
    max-width: 230px !important;
    margin-left: 0 !important;
  }
  #formBody #submitEmail button {
    margin-top: 20px;
  }
  #formBody .book-modal {
    margin: 0 10px 10px 0px !important;
  }
  #formBody .bookingStep {
    margin-top: 0 !important;
  }
  #formBody #emailForm {
    margin-top: 0 !important;
  }
  #formBody button {
    margin-left: 0;
  }
}

@media (min-width: 940px) {
  #formBody .Modal {
    margin: 0 25vw !important;
  }
  #formBody .book-li {
    width: 30%;
    justify-content: flex-start;
  }
  #formBody .ReactModal__Content--after-open {
    margin: 0 25vw;
  }
}

/* ======================================================
                            PreFooter
   ========================================================*/
.preFooter {
  color: #183041;
  border-top: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0 0 0 0;
}

.preFooter input {
  width: 200px;
  height: 40px;
  border: 1px solid #183041;
  border-radius: 3px;
  margin: 20px 0;
  padding: 0 10px;
  font-size: 16px;
}

.preFooter button {
  background-color: #183041;
  color: white;
  height: auto;
  font-size: 18px;
  margin: 0 10px;
  border-radius: 3px;
  -ms-grid-row-align: center;
  align-self: center;
  padding: 10px;
}

.preFooter button:hover {
  background-color: #ff8800;
}

.preFooter h1 {
  font-family: Poppins, sans-serif;
  color: #10151a;
  text-align: center;
  font-size: 26px;
  line-height: 30px;
  padding: 10px 20px;
  margin: 40px 0 10px 0;
}

.preFooter h2 {
  font-family: Poppins, sans-serif;
  color: #ff8800;
  text-align: center;
  font-size: 18px !important;
  line-height: 25px;
  padding: 0 20px;
  margin: 0;
}

.preFooter .bold {
  color: #183041;
  text-align: center;
  font-size: 18px;
  line-height: 25px;
  padding: 0 20px;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.preFooter .preFooterh2 {
  flex: 1 1;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 940px) {
  .preFooter h1 {
    font-size: 23px !important;
    margin: 25px 0 0 0 !important;
  }
  .preFooter h2 {
    font-size: 16px;
  }
  .preFooter .bold {
    font-size: 16px;
  }
}

.preFooter p {
  font-size: 14px;
}

/* ======================================================
                            Footer
   ========================================================*/
.footer {
  width: auto;
  height: auto;
  background-color: #183041;
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 19px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  /*     @media(min-width: map-get($breakpoints, flex-change)){
      width: 50%
    } */
}

.footer p {
  margin: 5px 0;
}

.footer a {
  margin: 5px 0;
}

.footer input {
  margin: 10px 0;
  background-color: white;
  width: 150px;
}

.footer .footerFlex {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 12px;
  height: auto;
  padding: 5px 0 0 0;
}

.footer .footerFlex p {
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 13px;
  margin: 0 0;
}

.footer .scenerieFooter {
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 15px !important;
  padding: 0 5px !important;
  letter-spacing: 1px;
}

.footer .copyright {
  font-size: 10px;
  color: #615e5e;
}

.footer img {
  width: 15px;
  height: 15px;
  text-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  padding: 0 5px;
  margin: 0 0;
}

.footer .madeWithLove {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer .igIcon {
  padding: 0 0 0 5px !important;
}

@media (max-width: 320px) {
  .scenerieHeader {
    font-size: 18px !important;
  }
  .scenerieCard h3 {
    font-size: 16px !important;
  }
  .footer input {
    margin: 10px 40px;
  }
}

/* =============================================================
  Signup
  ================================================================ */
.example .example_image {
  width: 100vw;
  height: 38.5vh;
  object-fit: scale-down;
  z-index: 1;
}

.example .example_image img {
  object-fit: scale-down !important;
  width: 100%;
  height: auto;
}

.example .example_image_overlay {
  position: absolute;
  width: 100vw;
  height: 305px;
  top: 0;
  background-color: #dee2e688;
  z-index: 2;
  align-items: center;
  display: flex;
  justify-content: center;
}

.example .example_image_overlay h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
}

.example .example_description {
  position: relative;
  padding: 0 10px;
}

.example .example_description h2 {
  font-family: Poppins, sans-serif;
  font-size: 22px;
  line-height: 30px;
  margin: 15px 0;
}

.example .example_description h3 {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  line-height: 30px;
  margin: 5px 0;
}

.example .example_description ul {
  padding: 0 0 0 30px;
}

.example .example_description ul li {
  padding: 5px 0;
}

.travelMates {
  width: 100vw;
  height: 100vh;
  margin: 0 0;
  padding: 0 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.travelMates h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif !important;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  padding: 30px 20px;
  color: #14304cff;
}

.travelMates h2, .travelMates h3 {
  color: #6DA1D5;
}

.travelMates p {
  color: #14304cff;
}

.travelMates .travelMates_overview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  flex: 1 1;
  justify-content: center;
  padding: 0 0;
}

.travelMates .travelMates_card, .travelMates .travelMates_cardSelected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  width: 50vw;
  height: 25vw;
  background-color: white;
  border-radius: 3px;
  margin: 8px;
}

.travelMates .travelMates_card img, .travelMates .travelMates_cardSelected img {
  width: 50%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.travelMates .travelMates_card p, .travelMates .travelMates_cardSelected p {
  width: 50%;
  margin: 0 0;
  padding: 0 0;
  text-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.travelMates .travelMates_cardSelected {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 16px !important;
}

.travelMates .travelMates_cardSelected p {
  font-weight: 600;
}

.learnMore {
  width: 50px !important;
  height: 50px !important;
}

#intro {
  width: 100vw;
  height: 100vh;
  margin: 0 0;
  padding: 0 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#intro h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif !important;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  padding: 30px 20px;
  color: #14304cff;
}

#intro h2, #intro h3 {
  color: #6DA1D5;
}

#intro p {
  color: #14304cff;
}

#intro .introAll {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  margin-bottom: 12vh;
}

#intro .introContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  height: 75vh;
  width: 80vw;
  background-color: white;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  margin-bottom: 30px;
}

#intro .introContainer_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  /*       .intro_retreat_img {
        @include flex_center(row);
        justify-content: space-between;
        margin: 60px 20px;

        img {
          width: 50px;
          height: auto;
        }
      } */
  /*       .introList {
        padding: 0px 0px 10px 0;
        width: 80%;
      }
      ol {
        width: 100%;
        margin: 0px 20px 10px 20px;
        object-fit: contain;
        list-style-type: ;
      }

      li {
        padding: 5px 10px 0 15px;
        color: #14304cff;
      }

      li::marker {
        color: #6DA1D5;
      } */
}

#intro .introContainer_content img {
  width: 100%;
  height: 40%;
  object-fit: contain;
}

#intro .introContainer_content h3 {
  font-family: Poppins, sans-serif !important;
  font-size: 20px;
  line-height: 30px;
  margin: 10px 0;
  text-align: center;
  padding: 0 5px;
  margin: 0 0 20px 0;
}

#intro .introContainer_content p {
  text-align: center;
  padding: 0 20px;
}

#intro .introContainer_content .introList {
  padding: 0px 0px 10px 0;
  width: 80%;
}

#intro .introContainer_content ol {
  max-width: 350px;
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 40px;
}

#intro .introContainer_content ol li {
  margin: 0 0 0.5rem 0;
  counter-increment: my-awesome-counter;
  position: relative;
  color: #14304cff;
  -ms-grid-row-align: center;
  align-self: center;
  padding: 0 0 10px 10px;
}

#intro .introContainer_content ol li::before {
  content: counter(my-awesome-counter);
  color: white;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  --size: 32px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  background: #6DA1D5;
  border-radius: 50%;
  text-align: center;
}

#intro .introContainer_skip {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5%;
  justify-content: space-evenly;
}

#intro .introContainer_skip img {
  width: 40px;
}

.backAndNext {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12vh;
}

.next, .back {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-items: flex-end;
  align-self: flex-end;
  padding: 30px 50px 20px 50px;
}

.next img, .back img {
  width: 40px;
  height: auto;
  padding: 0 0;
  margin: 0 0;
}

.next p, .back p {
  margin: 0 0;
  padding: 0 0;
  font-size: 14px;
}

#vibe {
  width: 100vw;
  height: 100vh;
  margin: 0 0;
  padding: 0 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#vibe h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif !important;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  padding: 30px 20px;
  color: #14304cff;
}

#vibe h2, #vibe h3 {
  color: #6DA1D5;
}

#vibe p {
  color: #14304cff;
}

#vibe .vibeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  flex: 1 1;
  justify-content: center;
  padding: 0 0;
}

#vibe .vibeCard, #vibe .vibeCardSelected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  width: 50vw;
  height: 25vw;
  background-color: white;
  border-radius: 3px;
  margin: 8px;
}

#vibe .vibeCard img, #vibe .vibeCardSelected img {
  width: 50%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

#vibe .vibeCard p, #vibe .vibeCardSelected p {
  width: 50%;
  margin: 0 0;
  padding: 0 10px;
  text-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#vibe .vibeCardSelected {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 16px !important;
}

#vibe .vibeCardSelected p {
  font-weight: 600;
}

.prices {
  width: 100vw;
  height: 100vh;
  margin: 0 0;
  padding: 0 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.prices h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif !important;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  padding: 30px 20px;
  color: #14304cff;
}

.prices h2, .prices h3 {
  color: #6DA1D5;
}

.prices p {
  color: #14304cff;
}

.prices .pricesSlider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  justify-content: center;
  width: 80vw;
}

.prices .pricesSlider p {
  margin-bottom: 40px;
}

.bottom-signup {
  height: 10%;
  width: 100vw;
}

#signIn {
  width: 100vw;
  height: 100vh;
  margin: 0 0;
  padding: 0 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  height: auto;
}

#signIn h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif !important;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  padding: 30px 20px;
  color: #14304cff;
}

#signIn h2, #signIn h3 {
  color: #6DA1D5;
}

#signIn p {
  color: #14304cff;
}

#signIn .signinContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

#signIn p {
  padding: 20px 20px 30px 20px;
  text-align: center;
  margin: 0 10px;
  font-size: 20px;
}

#signIn .explanationCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  border-radius: 3px;
  margin: 10px 0 10px 0;
}

#signIn .scenerieRetreats {
  font-family: Georgia, Times, 'Times New Roman', serif !important;
  font-weight: 400;
  font-size: 28px;
  color: #6DA1D5;
}

#signIn .explanationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px 0;
}

#signIn .explanationColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#signIn .explanationColumn img {
  width: 40px;
  height: 40px;
}

#signIn .explanationColumn .plusSign {
  font-size: 30px;
}

#signIn .retreatAttributes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  border-top: 0.5px solid #dee2e6;
  font-weight: 600;
}

#signIn .retreatAttributes p {
  font-size: 14px;
  margin: 0;
}

#signIn form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0 10px 0;
}

#signIn form button {
  border-radius: 3px;
  background-color: #204E7D;
  color: white;
  width: 230px;
  padding: 5px 5px;
  font-size: 20px;
  height: auto;
  border: 1px solid #204E7D;
  margin: 10px 0;
}

#signIn form button:hover {
  background-color: #A1C5DFFf;
}

#signIn form input {
  border-radius: 3px;
  background-color: #dee2e688;
  border: 1px solid #dee2e688;
  width: 230px;
  padding: 5px 0;
  font-size: 18px;
  height: auto;
  margin: 10px 0;
  text-align: center;
}

#signIn form input:focus {
  outline: none;
}

#signIn form input::-webkit-input-placeholder {
  text-align: center;
}

#signIn form input:-ms-input-placeholder {
  text-align: center;
}

#signIn form input::-ms-input-placeholder {
  text-align: center;
}

#signIn form input::placeholder {
  text-align: center;
}

#signIn .backAndNext {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.calendar {
  width: 100vw;
  height: 100vh;
  margin: 0 0;
  padding: 0 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.calendar h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif !important;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  padding: 30px 20px;
  color: #14304cff;
}

.calendar h2, .calendar h3 {
  color: #6DA1D5;
}

.calendar p {
  color: #14304cff;
}

.calendar .calendarContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  justify-content: space-evenly;
}

.calendar p {
  margin: 0;
  text-align: center;
  font-size: 20px;
}

.allSet {
  width: 100vw;
  height: 100vh;
  margin: 0 0;
  padding: 0 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
}

.allSet h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif !important;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  padding: 30px 20px;
  color: #14304cff;
}

.allSet h2, .allSet h3 {
  color: #6DA1D5;
}

.allSet p {
  color: #14304cff;
}

.allSet .allSetContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1;
}

.allSet .allSetContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1;
  margin-bottom: 40px;
}

.allSet .allSetContent img {
  width: 100vw;
  object-fit: cover;
  margin: 20px 0;
}

.allSet .allSetIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.allSet .celebrate {
  margin: 30px 0;
  width: 50px;
  height: auto;
}

.allSet p {
  font-size: 25px;
  text-align: center;
  margin: 20px;
}

.allSet .footer {
  background-color: white;
  margin-bottom: 12vh;
}

.saveChanges {
  cursor: pointer;
  min-width: 130px;
  color: white;
  background-color: #235745ff;
  padding: 10px 10px;
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  margin: 10px 10px;
}

.saveChanges:hover {
  cursor: pointer;
}

.clearButton {
  background-color: transparent;
  border: 0;
  color: #4a90e2;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  margin: 10px 10px;
}

#admin {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

#controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 10vh;
  border-bottom: 1px solid #d0d0d3;
}

#controls #dateRange {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#mapContainer {
  display: flex;
  flex-direction: row;
  height: 90vh;
}

#map {
  width: 50%;
  height: 100%;
}

#map .marker img {
  position: absolute;
  transform: translate(-50%, -50%);
}

#map img:hover {
  cursor: pointer;
}

#sideBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
  overflow: scroll;
}

#sideBar #admin_tagging {
  height: 50%;
}

#sideBar #propertyImages, #sideBar #activityImages {
  margin-top: 10px;
  text-align: center;
}

#sideBar #propertyImages img, #sideBar #activityImages img {
  max-height: 35vh;
  width: 40vw;
  object-fit: contain;
}

#sideBar #propertyData .element, #sideBar #activityData .element {
  margin: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 500;
}

#sideBar #propertyData label, #sideBar #activityData label {
  display: flex;
  align-items: center;
  flex: 1 1;
}

#sideBar #propertyData input[type=text], #sideBar #propertyData textarea, #sideBar #activityData input[type=text], #sideBar #activityData textarea {
  margin-left: 10px;
  padding: 3px 5px;
  box-sizing: border-box;
  font-size: 16px;
  flex: 1 1;
}

#sideBar #propertyData a, #sideBar #activityData a {
  margin-left: 10px;
}

#admin_tagging {
  width: 100vw;
  /*   @include flex_center(column); */
  /*   justify-content: flex-start;
  align-content: flex-start; */
}

#admin_tagging .admin_tagging_goBack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 10vh;
  padding: 20px 0;
}

#admin_tagging .admin_tagging_goBack img {
  width: 20px;
  height: auto;
}

#admin_tagging .admin_tagging_content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100vw;
  height: 80vh;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_arrow {
  width: 10vw;
  height: 80vh;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_arrow img {
  width: 20px;
  height: auto;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details {
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: space-between !important;
  align-items: flex-start;
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box {
  width: 80vw !important;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box .admin_tagging_content_details_box_img {
  width: 80vw !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  max-height: 60vh;
  margin-bottom: 10px;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box .admin_tagging_content_details_box_img img {
  max-width: 80vw;
  max-height: 50vh;
  object-fit: contain;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box .admin_tagging_content_details_box_tags {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 10px 0;
  width: 80vw;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box p {
  padding: 0 5px;
}

#admin_tagging .admin_tagging_content .admin_tagging_content_details_box h2, #admin_tagging .admin_tagging_content .admin_tagging_content_details_box h3 {
  padding: 5px 0;
}

/* General Settings */
body,
html, #root {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif !important;
  color: black;
  outline: none;
  scroll-behavior: smooth;
}

button:focus, input:focus {
  outline: none;
}
/*# sourceMappingURL=App.css.map */
